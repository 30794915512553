import React, { useState, useEffect, useCallback, useRef } from "react"
import VehicleRecommendation from "../components/recommendation/VehicleRecommendation"
import VehicleRecommendationV2 from "../components/recommendation/VehicleRecommendationV2"
import VDPModalContainer from "../components/vdp/VDPModalContainer"

import { FinanceCalculatorProvider } from "../components/modal/financing/storage/CalculatorContext"

export default function SearchRecommended({ props }) {
  const searchdata = typeof props !== 'undefined' ? props : {
    siteSettings: { theme: { colors: {} }, dealers: { dealerRef: [] } },
    StateResults: [],
    setFavoriteItems: () => { },
    favoriteItem: () => { },
    msrpClass: () => { },
    formatPrice: () => { },
    sortTheItems: () => { },
    lead_url: "",
    site_id: "",
    disclaimerCalculator:"",
    disclaimerContact:"",
    disclaimerFinanceApp:"",
    rates:[1,2,3,4,5,6,7],
    downpayment:15,
    defaultMonth:72,
    useCompactCards: false,
    overwriteCTAs: false
  }

  const dealers = searchdata.siteSettings.dealers
  const { rates, downpayment, defaultMonth } = searchdata
  const { disclaimerCalculator, disclaimerContact, disclaimerFinanceApp, dealerPhoneDiction } = searchdata
  const { lead_url, site_id,gtmId } = searchdata
  const { filterState, overwriteCTAs, overwriteInlineOffers, staticBtns, staticSrpInlineOffers} = searchdata
  const {DealerMasterEvent} = searchdata


  const localStorage = typeof window !== 'undefined' && window.localStorage
  let colorTheme = searchdata.siteSettings.theme.colors
  const { StateResults } = searchdata
  const { theSlug, formatPrice, sortTheItems } = searchdata
  const { SRPCardBtns } = searchdata
  const [hasMounted, setHasMounted] = React.useState(false);
  const [page, setPage] = useState(1)
  const [StateFavorites, setFavorites] = useState(getFavorites());
  let useCompactCards = searchdata.hasOwnProperty('useCompactCards') 
    ? searchdata.useCompactCards : false


  function appendSlash(slug) {
    const lastChar = slug.charAt(slug.length - 1)
    return lastChar === '/' ? slug : slug + '/'
  }

  function getFavorites() {
    let favs = getStorageItem("favoriteItem"+site_id)
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    return theFavs
  }

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  }

  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }

  function isFavorite(vin) {
    return StateFavorites.findIndex((v) => (v == vin)) !== -1
  }

  function favoriteItem(vin) {
    let favs = getStorageItem("favoriteItem"+site_id)
    let theFavs = (favs === null ? [] : JSON.parse(favs))
    theFavs = theFavs.filter((value, index, self) => self.indexOf(value) === index)
    if (!theFavs.includes(vin)) {          //checking weather array contain the id
      theFavs.push(vin)
    } else {
      theFavs.splice(theFavs.indexOf(vin), 1);  //deleting
    }
    setStorageItem("favoriteItem"+site_id, JSON.stringify(theFavs));
    setFavorites(theFavs);
    props.setFavorites(theFavs)
  }

  const observer = useRef()
  const lastItem = useCallback((node) => {

    if(typeof window !== 'undefined'){
      setTimeout(window.dispatchEvent(DealerMasterEvent),1000);
    }
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage(page + 1)
      }
    });
    if (node)
      observer.current.observe(node);
  }
  )

  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <>
      <FinanceCalculatorProvider>
        <VDPModalContainer
          siteData={{
            lead_url, site_id, gtmId,
            disclaimerContact, disclaimerFinanceApp, disclaimerCalculator, dealers
          }}
          financeData={{ rates, downpayment, defaultMonth }
        }
        />
        <section className="sr-container">
          <div className="srp-loader srp-loader-center srp-loading-show"></div>
          <ul className="sr srp-loading-hide">
            {StateResults.hits.slice(0, Math.min(6 * page, StateResults.total)).map((result, index) => {
              let item = result
              let cnt = (6 * page) - 3
              return (
                <li ref={index == cnt ? lastItem : {}} className={"sr-item sr-item_page_" + Math.ceil((index + 1) / 10)}>
                {useCompactCards ? 
                  <VehicleRecommendationV2
                    fallbackText={props.fallbackText}
                    isFavorite={isFavorite(item.VehicleInfo.VIN)}
                    favorite={favoriteItem}
                    theSlug={appendSlash(theSlug)}
                    item={item}
                    unavailableImg={props.unavailableImg}
                    SRPCardBtns={SRPCardBtns}
                    formatPrice={formatPrice}
                    phoneNumber={dealerPhoneDiction[item.VehicleInfo.DealerID]}
                    overwriteCTAs={overwriteCTAs}
                    staticBtns={staticBtns}
                  /> :
                  <VehicleRecommendation
                    fallbackText={props.fallbackText}
                    isFavorite={isFavorite(item.VehicleInfo.VIN)}
                    favorite={favoriteItem}
                    theSlug={appendSlash(theSlug)}
                    item={item}
                    unavailableImg={props.unavailableImg}
                    SRPCardBtns={SRPCardBtns}
                    formatPrice={formatPrice}
                    phoneNumber={dealerPhoneDiction[item.VehicleInfo.DealerID]}
                    overwriteCTAs={overwriteCTAs}
                    staticBtns={staticBtns}
                  />}
                </li>
              )
            })}
          </ul>
        </section>
      </FinanceCalculatorProvider>
    </>
  )
}
